@tailwind base;
@tailwind components;
@tailwind utilities;



.hideLetters .mainGrid .text-white {
  color: rgba(0, 0, 0, 0);
}

a:focus-visible {
    outline: none;
}